<template>
  <div>
    <v-card-title>
      {{$t('all')}}
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        :label="$t('search')"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="bankCardList"
      :search="search"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{$t('bank_card')}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on }">
              <v-btn color="primary" dark class="mb-2" v-on="on">{{$t('new_bank_card')}}</v-btn>
            </template>
            <bank-card-form
              :form-title="formTitle"
              :edited-item="editedItem" :edited-index="editedIndex"
              :dialog-visible="dialog"
              @fetch="fetch" @close="close"
            ></bank-card-form>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.max_daily_tx_amt="{ item }">
        {{ item.max_daily_tx_amt ? item.max_daily_tx_amt.toLocaleString(undefined, {minimumFractionDigits: 2}) : ''}}
      </template>
      <template v-slot:item.max_tx_limit="{ item }">
        {{ item.max_tx_limit ? item.max_tx_limit.toLocaleString() : '' }}
      </template>
      <template v-slot:item.max_hourly_tx_limit="{ item }">
        {{ item.max_hourly_tx_limit ? item.max_hourly_tx_limit.toLocaleString() : ''}}
      </template>
      <template v-slot:item.min_tx_amt="{ item }">
        {{ item.min_tx_amt ? item.min_tx_amt.toLocaleString(undefined, {minimumFractionDigits: 2}) : '' }}
      </template>
      <template v-slot:item.max_tx_amt="{ item }">
        {{ item.max_tx_amt ? item.max_tx_amt.toLocaleString(undefined, {minimumFractionDigits: 2}) : ''}}
      </template>
      <template v-slot:item.assign_client="{ item }">
        <v-tooltip v-if="item.assign_client" bottom>
          <template v-slot:activator="{ on, attrs }">
            <a
              :href="item.assign_client ? 'javascript:void(0);' : false"
              v-bind="attrs"
              v-on="on"
            >
              {{ item.assign_client.length }}
            </a>
          </template>
          <div v-if="item.assign_client.length === 0">
            {{$t('no_assigned_clients')}}
          </div>
          <div v-else>
            <div :key="id" v-for="id in item.assign_client">
              {{ id }}
            </div>
          </div>
        </v-tooltip>
      </template>
      <template v-slot:item.action="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="fetch">{{$t('refresh')}}</v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
  import axios from 'axios'
  import BankCardForm from "./BankCardForm";

  export default {
    name: 'AllContact',
    components: {BankCardForm},


    mounted() {
      this.fetch();
      this.fetchBank();
    },
    computed: {
      headers () {
        return [
          {
            text: this.$t('bank_card_id'),
            align: 'left',
            sortable: true,
            value: 'bank_card_id',
          },
          { text: this.$t('bank_card_bank_code'), value: 'bank_card_bank_code' },
          { text: this.$t('bank_card_name'), value: 'bank_card_name' },
          { text: this.$t('bank_card_number'), value: 'bank_card_number' },
          { text: this.$t('bank_card_enabled'), value: 'bank_card_enabled' },

          { text: this.$t('max_daily_tx_amt'), value: 'max_daily_tx_amt' },
          { text: this.$t('max_tx_limit'), value: 'max_tx_limit' },
          { text: this.$t('max_hourly_tx_limit'), value: 'max_hourly_tx_limit' },
          { text: this.$t('min_tx_amt'), value: 'min_tx_amt' },
          { text: this.$t('max_tx_amt'), value: 'max_tx_amt' },

          { text: this.$t('assigned_clients'), value: 'assign_client' },
          { text: this.$t('actions'), value: 'action', sortable: false },
        ]
      },
      formTitle () {
        return this.editedIndex === -1 ? this.$t('new_bank') : this.$t('edit_bank')
      },
    },
    watch: {
      dialog (val) {
        val || this.close()
      },
    },
    data: () => ({
      dialog: false,
      busy: false,
      search: '',
      bankList: [],
      bankCardList: [],
      editedIndex: -1,
      editedItem: {
        bank_card_id: null,
        // bank_id: null,
        bank_card_name: null,
        bank_card_number: null,
        bank_card_enabled: true,

        currency_code: null,
        max_daily_tx_amt: null,
        max_tx_limit: null,
        max_hourly_tx_limit: null,
        min_tx_amt: null,
        max_tx_amt: null,

        bank_card_bank_code: null,

        bank_card_branch: '',
        bank_card_city: '',
        bank_card_province: '',

        last_tx_time: null,
        qr: '',
        newQr: null,

        paytype: [],
        bank_card_url: '',

        p2p_bank_code: null,
      },
      defaultItem: {
        bank_card_id: '',
        // bank_id: '',
        bank_card_name: '',
        bank_card_number: '',
        bank_card_enabled: true,

        currency_code: null,
        max_daily_tx_amt: null,
        max_tx_limit: null,
        max_hourly_tx_limit: null,
        min_tx_amt: null,
        max_tx_amt: null,

        bank_card_bank_code: '',
        bank_card_branch: '',
        bank_card_city: '',
        bank_card_province: '',

        last_tx_time: '',
        qr: '',
        newQr: null,

        paytype: [],
        bank_card_url: '',

        p2p_bank_code: null,
      },
    }),
    methods: {
      async fetchBank () {
        this.busy = true;
        const _this = this;
        await axios.get('/app/api/bank')
          .then(function (response) {
            _this.bankList = response.data.data;
          })
          .catch(function (error) {
            _this.$store.dispatch('snackbar/openSnackbar', {
              message: error.response.data.message,
              color: 'error',
            })
          })
          .finally(function () {
            _this.busy = false;
          })
      },
      async fetch () {
        this.busy = true;
        const _this = this;
        await axios.get('/app/api/bankcard')
          .then(function (response) {
            _this.bankCardList = response.data.data;
          })
          .catch(function (error) {
            _this.$store.dispatch('snackbar/openSnackbar', {
              message: error.response.data.message,
              color: 'error',
            })
          })
          .finally(function () {
            _this.busy = false;
          })
      },
      editItem (item) {
        this.editedIndex = this.bankCardList.indexOf(item)
        this.editedItem = Object.assign({}, item)

        this.dialog = true
      },
      deleteItem (item) {
        if (confirm(this.$t('delete_confirmation'))) {
          this.busy = true;
          const _this = this;
          axios.delete(`/app/api/bankcard/${item.bank_card_id}`)
            .then(function (response) {
              _this.$store.dispatch('snackbar/openSnackbar', {
                message: response.data.message,
                color: 'success',
              })
              _this.fetch();
            })
            .catch(function (error) {
              _this.$store.dispatch('snackbar/openSnackbar', {
                message: error.response.data.message,
                color: 'error',
              })
            })
            .finally(function () {
              _this.busy = false;
            })
        }
      },
      close () {
        this.dialog = false
        // setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        // }, 300)
      },
    },
  }
</script>

<template>
  <v-card>
    <v-card-title>
      <span class="headline">{{ formTitle }}</span>
      <v-spacer></v-spacer>
      <v-btn icon @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="pb-0">
      <!--                  y3 internal provider-->
      <v-container
        v-if="editedItem.service_provider_id === 0"
      >
        <v-row>
          <v-col cols="12" v-if="editedItem.collectb_sts !== undefined && editedItem.collectb_sts !== null">
            <v-text-field
              :label="$t('sts')"
              :value="$t(getKeyByValue(CollectBotSts, editedItem.collectb_sts, CollectBotSts.PENDING))"
              type="text"
              :class="getCollectBotStsColor(editedItem.collectb_sts)"
              :filled="editedIndex > -1"
              :readonly="editedIndex > -1"
            />
          </v-col>
        </v-row>
        <v-row v-if="isCsAgentBot">
          <template v-if="editedItem.collectb">
            <v-col cols="6">
              <v-text-field
                :label="$t('bank_id')"
                v-model="editedItem.collectb.bank_id"
                type="text"
                :filled="editedIndex > -1"
                :readonly="editedIndex > -1"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                :label="$t('bank_name')"
                v-model="editedItem.collectb.bank_name"
                type="text"
                :filled="editedIndex > -1"
                :readonly="editedIndex > -1"
              >
                <template v-if="isCsAgentBot" #append>
                  <v-btn icon :href="editedItem.collectb.bank_url" target="_blank">
                    <v-icon>mdi-link</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>

            <template
              v-if="!(editedItem.collectb.sts === CollectBotSts.COMPLETED
                || editedItem.collectb.sts === CollectBotSts.REJECTED)"
            >
              <v-col cols="6">
                <v-text-field
                  :label="$t('username')"
                  v-model="editedItem.collectb.username"
                  type="text"
                  append-outer-icon="mdi-content-copy"
                  @click:append-outer="copyToClipboard(editedItem.collectb.username, $t('username'))"
                  :filled="editedIndex > -1"
                  :readonly="editedIndex > -1"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  :label="$t('password')"
                  v-model="editedItem.collectb.password"
                  type="password"
                  append-outer-icon="mdi-content-copy"
                  @click:append-outer="copyToClipboard(editedItem.collectb.password, $t('password'))"
                  :filled="editedIndex > -1"
                  :readonly="editedIndex > -1"
                />
              </v-col>
            </template>
            <v-col cols="6">
              <v-autocomplete
                :filled="is2faReqSubmitted ||
                  editedItem.collectb.sts === CollectBotSts.ENTER_AUTH ||
                  editedItem.collectb.sts === CollectBotSts.COMPLETED ||
                  editedItem.collectb.sts === CollectBotSts.REJECTED"
                :readonly="is2faReqSubmitted ||
                  editedItem.collectb.sts === CollectBotSts.ENTER_AUTH ||
                  editedItem.collectb.sts === CollectBotSts.COMPLETED ||
                  editedItem.collectb.sts === CollectBotSts.REJECTED"
                :label="$t('auth_method')"
                name="auth_method"
                :items="authMethods"
                item-text="name"
                item-value="code"
                v-model="editedItem.collectb.auth_method"
                :append-outer-icon="is2faReqSubmitted ||
                editedItem.collectb.sts === CollectBotSts.ENTER_AUTH ||
                editedItem.collectb.sts === CollectBotSts.COMPLETED ||
                editedItem.collectb.sts === CollectBotSts.REJECTED ? null : 'mdi-lock-alert-outline'"
                @click:append-outer="collect2fa"
              ></v-autocomplete>
            </v-col>
            <v-col
              v-if="!(editedItem.collectb.sts === CollectBotSts.COMPLETED
                || editedItem.collectb.sts === CollectBotSts.REJECTED)"
              cols="6"
            >
              <v-text-field
                :label="$t('auth_value')"
                v-model="editedItem.collectb.auth_value"
                append-icon="mdi-refresh"
                @click:append="refresh"
                append-outer-icon="mdi-content-copy"
                @click:append-outer="copyToClipboard(editedItem.collectb.auth_value, $t('auth_value'))"
                type="text"
                :filled="editedIndex > -1"
                :readonly="editedIndex > -1"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                :label="$t('auth_hint')"
                :readonly="is2faReqSubmitted ||
                  editedItem.collectb.sts === CollectBotSts.ENTER_AUTH ||
                  editedItem.collectb.sts === CollectBotSts.COMPLETED ||
                  editedItem.collectb.sts === CollectBotSts.REJECTED"
                type="text"
                :filled="is2faReqSubmitted ||
                  editedItem.collectb.sts === CollectBotSts.ENTER_AUTH ||
                  editedItem.collectb.sts === CollectBotSts.COMPLETED ||
                  editedItem.collectb.sts === CollectBotSts.REJECTED"
                v-model="editedItem.collectb.auth_hint"
              />
            </v-col>
          </template>
        </v-row>
        <v-row>
          <template v-if="editedItem.p2p">
            <v-col cols="6">
              <v-text-field
                :label="$t('p2p_sts')"
                :value="$t(getKeyByValue(P2pSts, editedItem.p2p.sts, P2pSts.PENDING))"
                type="text"
                :class="getP2pStsColor(editedItem.p2p.sts)"
                :filled="editedIndex > -1"
                :readonly="editedIndex > -1"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                :label="$t('p2p_trf_time')"
                v-model="computedP2pTrfTime"
                type="text"
                :filled="editedIndex > -1"
                :readonly="editedIndex > -1"
              />
            </v-col>
          </template>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-if="editedItem.client_order_id"
              :label="$t('client_order_id')"
              v-model="editedItem.client_order_id"
              type="text"
              :filled="editedIndex > -1"
              :readonly="editedIndex > -1"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-if="editedItem.paytype"
              :label="$t('paytype')"
              v-model="editedItem.paytype"
              type="text"
              :filled="editedIndex > -1"
              :readonly="editedIndex > -1"
            />
          </v-col>
          <template v-if="editedItem.card">
            <v-col cols="6">
              <v-text-field
                :label="$t('ref_num')"
                v-model="editedItem.card.ref_num"
                append-outer-icon="mdi-content-copy"
                @click:append-outer="copyToClipboard(editedItem.card.ref_num, $t('ref_num'))"
                type="text"
                :filled="editedIndex > -1"
                :readonly="editedIndex > -1"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                :label="$t('to_bankflag')"
                v-model="editedItem.card.bank_card_bank_code"
                append-outer-icon="mdi-content-copy"
                @click:append-outer="copyToClipboard(editedItem.card.bank_card_bank_code, $t('to_bankflag'))"
                type="text"
                :filled="editedIndex > -1"
                :readonly="editedIndex > -1"
              >
                <template v-if="isCsAgent" #append>
                  <v-btn icon :href="editedItem.card.bank_card_url" target="_blank">
                    <v-icon>mdi-link</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                :label="$t('to_cardnumber')"
                v-model="editedItem.card.bank_card_number"
                append-outer-icon="mdi-content-copy"
                @click:append-outer="copyToClipboard(editedItem.card.bank_card_number, $t('to_cardnumber'))"
                type="text"
                :filled="editedIndex > -1"
                :readonly="editedIndex > -1"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                :label="$t('to_cardname')"
                v-model="editedItem.card.bank_card_name"
                append-outer-icon="mdi-content-copy"
                @click:append-outer="copyToClipboard(editedItem.card.bank_card_name, $t('to_cardname'))"
                type="text"
                :filled="editedIndex > -1"
                :readonly="editedIndex > -1"
              />
            </v-col>
          </template>
          <template
            v-if="editedItem.transaction_type === TransactionType.TOP_UP || editedItem.transaction_type === TransactionType.COLLECT">
            <v-col cols="6">
              <v-text-field
                :label="$t('from_bankflag')"
                v-model="editedItem.bank.bank"
                type="text"
                :filled="editedIndex > -1"
                :readonly="editedIndex > -1"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                :label="$t('from_cardnumber')"
                v-model="editedItem.beneficiary_account"
                type="text"
                :filled="editedIndex > -1"
                :readonly="editedIndex > -1"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                :label="$t('from_cardname')"
                v-model="editedItem.beneficiary_name"
                type="text"
                :filled="editedIndex > -1"
                :readonly="editedIndex > -1"
              />
            </v-col>
          </template>
          <template
            v-else-if="editedItem.transaction_type === TransactionType.WITHDRAW || editedItem.transaction_type === TransactionType.PAY">
            <v-col cols="6">
              <v-text-field
                v-if="editedItem.bank"
                :label="$t('to_bankcode')"
                v-model="editedItem.bank.bank_code"
                type="text"
                :filled="editedIndex > -1"
                :readonly="editedIndex > -1"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-if="editedItem.bank"
                :label="$t('to_bankflag')"
                v-model="editedItem.bank.bank"
                type="text"
                :filled="editedIndex > -1"
                :readonly="editedIndex > -1"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                :label="$t('to_cardnumber')"
                v-model="editedItem.beneficiary_account"
                type="text"
                :filled="editedIndex > -1"
                :readonly="editedIndex > -1"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                :label="$t('to_cardname')"
                v-model="editedItem.beneficiary_name"
                type="text"
                :filled="editedIndex > -1"
                :readonly="editedIndex > -1"
              />
            </v-col>
          </template>
          <v-col cols="6">
            <v-text-field
              :label="$t('amount')"
              v-model="editedItem.amount"
              append-outer-icon="mdi-content-copy"
              @click:append-outer="copyToClipboard(editedItem.amount, $t('amount'))"
              type="text"
              :filled="editedIndex > -1"
              :readonly="editedIndex > -1"
            />
          </v-col>
          <v-col cols="6" v-if="editedItem.commission">
            <v-text-field
              :label="$t('commission')"
              v-model="editedItem.commission"
              type="text"
              :filled="editedIndex > -1"
              :readonly="editedIndex > -1"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              :label="$t('net_amount')"
              v-model="editedItem.net_amount"
              type="text"
              :filled="editedIndex > -1"
              :readonly="editedIndex > -1"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              :label="$t('creation_time')"
              v-model="computedCreationTime"
              type="text"
              :filled="editedIndex > -1"
              :readonly="editedIndex > -1"
            />
          </v-col>
          <v-col 
            cols="6" 
            v-if="
              (isRoot || isManager) && 
              editedItem.time_taken !== null && 
              editedItem.time_taken !== undefined && 
              (
                editedItem.transaction_type === TransactionType.TOP_UP || 
                editedItem.transaction_type === TransactionType.COLLECT ||
                editedItem.transaction_type === TransactionType.WITHDRAW || 
                editedItem.transaction_type === TransactionType.PAY
              )
            "
          >
            <v-text-field
              :label="$t('transaction_time_taken')"
              v-model="computedTransactionTimeTaken"
              type="text"
              :filled="editedIndex > -1"
              :readonly="editedIndex > -1"
            />
          </v-col>
          <v-col
            cols="12"
            v-if="editedItem.transaction_type === TransactionType.TOP_UP
                 || editedItem.transaction_type === TransactionType.COLLECT"
          >
            <v-dialog
              fullscreen hide-overlay
              v-model="customerReceiptDialog"
              v-if="editedItem.receipt"
            >
              <template
                v-slot:activator="{ on, attrs }"
              >
                <v-hover v-slot:default="{ hover }">
                  <v-img
                    :src="apiEndpoint + editedItem.receipt"
                    class="primary"
                    height="125"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <div
                      v-if="hover"
                      class="d-flex transition-fast-in-fast-out primary darken-2 v-card--reveal display-1 white--text"
                      style="height: 100%;"
                    >
                      {{ $t('click_expand_receipt') }}
                    </div>
                  </v-img>
                </v-hover>
              </template>
              <v-card>
                <v-toolbar dark color="primary">
                  <v-toolbar-title>{{ $t('customer_receipt') }}</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-items>
                    <v-btn icon dark @click="customerReceiptDialog = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar-items>
                </v-toolbar>
                <v-img
                  :src="apiEndpoint + editedItem.receipt"
                ></v-img>
              </v-card>
            </v-dialog>
          </v-col>
          <v-col
            cols="12"
            v-if="editedItem.p2p && editedItem.p2p.receipt"
          >
            <v-dialog
              fullscreen hide-overlay
              v-model="p2pReceiptDialog"
              v-if="editedItem.p2p && editedItem.p2p.receipt"
            >
              <template
                v-slot:activator="{ on, attrs }"
              >
                <v-hover v-slot:default="{ hover }">
                  <v-img
                    :src="apiEndpoint + editedItem.p2p.receipt"
                    class="primary"
                    height="125"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <div
                      v-if="hover"
                      class="d-flex transition-fast-in-fast-out primary darken-2 v-card--reveal display-1 white--text"
                      style="height: 100%;"
                    >
                      {{ $t('click_expand_receipt') }}
                    </div>
                  </v-img>
                </v-hover>
              </template>
              <v-card>
                <v-toolbar dark color="primary">
                  <v-toolbar-title>{{ $t('customer_receipt') }}</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-items>
                    <v-btn icon dark @click="p2pReceiptDialog = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar-items>
                </v-toolbar>
                <v-img
                  :src="apiEndpoint + editedItem.p2p.receipt"
                ></v-img>
              </v-card>
            </v-dialog>
          </v-col>
          <v-col cols="12" v-if="editedItem.remark">
            <v-textarea
              v-if="editedItem.remark.client"
              :label="$t('client_remark')"
              auto-grow
              rows="3"
              v-model="editedItem.remark.client"
              prepend-icon="mdi-comment-outline"
              name="client_name"
              filled
              readonly
            ></v-textarea>
            <v-textarea
              v-if="editedItem.remark.cs_agent_b"
              :label="$t('remark_cs_agent_b')"
              auto-grow
              rows="3"
              v-model="editedItem.remark.cs_agent_b"
              prepend-icon="mdi-comment-outline"
              name="client_name"
              :filled="true"
              :readonly="true"
            ></v-textarea>
            <v-textarea
              v-if="editedItem.remark.user"
              :label="$t('remark')"
              auto-grow
              rows="3"
              v-model="editedItem.remark.user"
              prepend-icon="mdi-comment-outline"
              name="client_name"
              :filled="true"
              :readonly="true"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-container>
      <!--                  3rd party provider-->
      <v-container v-else>
        <v-row>
          <template v-if="editedItem.bank">
            <v-col cols="6" sm="3">
              <v-text-field
                :label="$t('to_bankflag')"
                v-model="editedItem.bank.bank"
                name="bank_code"
                type="text"
                :filled="editedIndex > -1"
                :readonly="editedIndex > -1"
              />
            </v-col>
            <v-col cols="6" sm="3">
              <v-text-field
                :label="$t('to_province')"
                v-model="editedItem.bank.province"
                name="branch_code"
                type="text"
                :filled="editedIndex > -1"
                :readonly="editedIndex > -1"
              />
            </v-col>
            <v-col cols="6" sm="3">
              <v-text-field
                :label="$t('to_city')"
                v-model="editedItem.bank.city"
                name="location_code"
                type="text"
                :filled="editedIndex > -1"
                :readonly="editedIndex > -1"
              />
            </v-col>
            <v-col cols="6" sm="3">
              <v-text-field
                :label="$t('to_branch')"
                v-model="editedItem.bank.branch"
                name="branch_code"
                type="text"
                :filled="editedIndex > -1"
                :readonly="editedIndex > -1"
              />
            </v-col>
          </template>
          <v-col cols="12" sm="6">
            <v-text-field
              :label="$t('amount')"
              :prefix="$app_currency_code"
              v-model="editedItem.amount"
              name="amount"
              type="number"
              filled
              readonly
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              :label="$t('commission')"
              :prefix="$app_currency_code"
              v-model="editedItem.commission"
              name="commission"
              type="number"
              :filled="editedIndex > -1"
              :readonly="editedIndex > -1"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              :label="$t('net_amount')"
              :prefix="$app_currency_code"
              v-model="editedItem.net_amount"
              name="net_amount"
              type="number"
              :filled="editedIndex > -1"
              :readonly="editedIndex > -1"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              :label="$t('beneficiary_account')"
              v-model="editedItem.beneficiary_account"
              name="client_name"
              type="text"
              filled
              readonly
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              :label="$t('beneficiary_name')"
              v-model="editedItem.beneficiary_name"
              name="client_name"
              type="text"
              filled
              readonly
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              :label="$t('client_id')"
              v-model="editedItem.client_id"
              name="client_name"
              type="text"
              filled
              readonly
            />
          </v-col>
          <v-col cols="12" v-if="editedItem.remark">
            <v-textarea
              v-if="editedItem.remark.client"
              :label="$t('client_remark')"
              auto-grow
              rows="3"
              v-model="editedItem.remark.client"
              prepend-icon="mdi-comment-outline"
              name="client_name"
              filled
              readonly
            ></v-textarea>
            <v-textarea
              v-if="editedItem.remark.cs_agent_b"
              :label="$t('remark_cs_agent_b')"
              auto-grow
              rows="3"
              v-model="editedItem.remark.cs_agent_b"
              prepend-icon="mdi-comment-outline"
              name="client_name"
              :filled="true"
              :readonly="true"
            ></v-textarea>
            <v-textarea
              v-if="editedItem.remark.user"
              :label="$t('remark')"
              auto-grow
              rows="3"
              v-model="editedItem.remark.user"
              prepend-icon="mdi-comment-outline"
              name="client_name"
              :filled="true"
              :readonly="true"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col
            v-if="editedItem.transaction_type === TransactionType.WITHDRAW || editedItem.transaction_type === TransactionType.PAY"
            cols="12"
          >
            <validation-provider v-if="isEditable" name="receipt" rules="required|image" v-slot="{ errors }"
                                 ref="receiptRef">
              <v-file-input
                :error-messages="errors"
                :label="$t('receipt')"
                outlined
                counter
                show-size
                v-model="editedForm.receipt"
                accept="image/*"
              ></v-file-input>
            </validation-provider>
            <!--                        <router-link-->
            <!--                          v-else-->
            <!--                          :href="editedItem.receipt"-->
            <!--                          target="_blank"-->
            <!--                        >-->
            <v-dialog
              fullscreen hide-overlay
              v-model="receiptDialog"
              v-if="editedItem.receipt"
            >
              <template
                v-slot:activator="{ on, attrs }"
              >
                <v-hover v-slot:default="{ hover }">
                  <v-img
                    :src="apiEndpoint + editedItem.receipt"
                    class="primary"
                    height="125"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <div
                      v-if="hover"
                      class="d-flex transition-fast-in-fast-out primary darken-2 v-card--reveal display-1 white--text"
                      style="height: 100%;"
                    >
                      {{ $t('click_expand_receipt') }}
                    </div>
                  </v-img>
                </v-hover>
              </template>
              <v-card>
                <v-toolbar dark color="primary">
                  <v-toolbar-title>{{ $t('receipt') }}</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-items>
                    <v-btn icon dark @click="receiptDialog = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar-items>
                </v-toolbar>
                <v-img
                  :src="apiEndpoint + editedItem.receipt"
                ></v-img>
              </v-card>
            </v-dialog>
          </v-col>
          <v-col cols="12"
                 v-if="editedItem.transaction_type === TransactionType.TOP_UP || editedItem.transaction_type === TransactionType.COLLECT">
            <template v-if="isCsAgentBot">

            </template>
            <template v-else>
              <validation-provider v-if="isEditable" name="amount" v-slot="{ errors }" ref="amountRef">
                <v-text-field
                  :error-messages="errors"
                  :label="$t('amount')"
                  outlined
                  v-model="editedForm.amount"
                  prepend-icon="mdi-cash"
                  name="amount"
                ></v-text-field>
              </validation-provider>
              <validation-provider v-if="isEditable" name="act_bank_card_name" v-slot="{ errors }"
                                   ref="actBankCardNameRef">
                <v-textarea
                  :error-messages="errors"
                  :label="$t('act_bank_card_name')"
                  outlined
                  auto-grow
                  rows="3"
                  v-model="editedForm.act_bank_card_name"
                  prepend-icon="mdi-comment-outline"
                  name="act_bank_card_name"
                ></v-textarea>
              </validation-provider>
              <v-textarea
                v-else
                :label="$t('act_bank_card_name')"
                auto-grow
                rows="3"
                v-model="editedItem.card.act_bank_card_name"
                prepend-icon="mdi-comment-outline"
                name="act_bank_card_name"
                :filled="!isEditable"
                :readonly="!isEditable"
              ></v-textarea>
            </template>
          </v-col>
          <v-col cols="12">
            <validation-provider v-if="isEditable" name="remark" rules="" v-slot="{ errors }" ref="remarkRef">
              <div v-if="isCsAgentBot">
                <v-chip
                  v-for="(msg, i) in rejectMessages"
                  :key="i"
                  class="ma-2" small
                  @click="editedForm.remark = msg"
                >{{ msg }}</v-chip>
              </div>
              <v-textarea
                :error-messages="errors"
                :label="$t('remark')"
                outlined
                auto-grow
                rows="3"
                v-model="editedForm.remark"
                prepend-icon="mdi-comment-outline"
                name="client_name"
              ></v-textarea>
            </validation-provider>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-text 
      v-if="
        (isRoot || isManager) && 
        (
          editedItem.transaction_status === TransactionStatus.COMPLETED ||
          editedItem.transaction_status === TransactionStatus.REJECTED
        ) &&
        editedItem.callback 
      "
    >
      <v-divider></v-divider>
      <v-container>
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              :label="$t('callback_url')"
              v-model="editedItem.callback.callback_url"
              type="text"
              readonly
              filled
            >
              <template v-slot:append-outer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon 
                      class="mr-2" 
                      v-bind="attrs" 
                      v-on="on"
                      @click="triggerManualCallback"
                      :disabled="busy"
                    >
                      mdi-cloud-sync-outline
                    </v-icon>
                  </template>
                  <span>{{$t('manual_callback')}}</span>
                </v-tooltip>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              :label="$t('callback_time')"
              v-model="computedCallbackTime"
              type="text"
              readonly
              filled
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              :label="$t('callback_status')"
              v-model="computedCallbackStatus"
              type="text"
              readonly
              filled
              :class="getCallbackStsColor(
                editedItem.callback.callback_rsp_status === 200
              )"
            />
          </v-col>
          <v-col cols="12">
            <v-textarea
              :label="$t('callback_response')"
              auto-grow
              rows="3"
              v-model="editedItem.callback.callback_rsp_data"
              filled
            ></v-textarea>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary darken-1" text @click="close">{{ $t('cancel') }}</v-btn>

      <template v-if="isEditable">
        <v-btn color="error" @click="reject" :loading="busy">{{ $t('reject') }}</v-btn>
        <v-btn
          color="success" @click="approveStep" :loading="busy"
          :disabled="editedItem.collectb_sts === CollectBotSts.REJECTED"
        >
          {{ $t('approve') }}
        </v-btn>
        <v-dialog v-model="approveDialog" width="500">
          <v-card>
            <v-card-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="approveDialog = null">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>

            <v-card-text class="text-center">
              <v-icon x-large color="yellow darken-2">mdi-alert</v-icon>
            </v-card-text>

            <v-card-text class="red--text text-center">
              {{ $t('please_double_check_transaction_amount') }}
            </v-card-text>
            <v-card-text class="red--text text-center">
              {{ $t('amount') }}: {{ editedForm.amount }}
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary darken-1" text @click="approveDialog = null">{{ $t('cancel') }}</v-btn>
              <v-btn color="success" @click="approve">{{ $t('confirm') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!--                    <v-btn color="success" @click="approve" :loading="busy">{{$t('approve')}}</v-btn>-->
      </template>
    </v-card-actions>
    <v-snackbar
      v-if="isCsAgentBot && isEditable"
      app right top
      light
      :timeout="-1"
      v-model="snackbar"
    >
      <span class="red--text text--darken-1">
        <v-progress-circular
          :rotate="270"
          :value="timerPercent"
          color="red"
        >
          {{ Math.ceil(timer / 1000) }}
        </v-progress-circular>
        <span class="ml-1">
          {{ $t('complete_within_1m') }}
        </span>
      </span>
    </v-snackbar>
  </v-card>
</template>

<script>
import axios from 'axios';
import FormData from 'form-data';
import {required, image, min_value, max_value} from 'vee-validate/dist/rules';
import {validate, extend, ValidationProvider} from 'vee-validate';
import transactionMixins from '../../mixins/transactionMixins';
import globalMixins from '../../mixins/globalMixins';
import {mapGetters} from "vuex";
import {AuthMethod} from "../../constants/transaction";
import {PayTypeEnum} from '../../constants/y3';

extend('required', required);
extend('image', image);
extend('min_value', min_value);
extend('max_value', max_value);

export default {
  name: 'TaskForm',
  props: ['formTitle', 'isEditable', 'editedItem', 'editedForm', 'editedIndex', 'is2faReqSubmitted', 'viewedAt'],
  components: {
    ValidationProvider,
  },
  mixins: [transactionMixins, globalMixins],
  mounted() {
    this.initTimer()
  },
  computed: {
    ...mapGetters({
      isRoot: 'auth/isRoot',
      isManager: 'auth/isManager',
      isCsAgent: 'auth/isCsAgent',
      isCsAgentBot: 'auth/isCsAgentBot',
    }),
    computedCreationTime: {
      get() {
        return new Date(this.editedItem.creation_time).toLocaleString()
      },
      set(val) {
        this.editedItem.creation_time = val
      }
    },
    computedTransactionTimeTaken: {
      get() {
        const timeTaken = this.editedItem.time_taken;

        if (timeTaken === null || timeTaken === undefined) {
          return null;
        }
        else {
          const mins = Math.trunc(timeTaken / 1000 / 60);
          const seconds = Math.trunc(timeTaken / 1000 % 60);

          return (
            `${mins} ${this.$t('minutes')} ${seconds} ${this.$t('seconds')}`
          );
        }
      }
    },
    computedP2pTrfTime: {
      get() {
        if (
          this.editedItem && this.editedItem.p2p && 
          this.editedItem.p2p.trf_time !== undefined &&
          this.editedItem.p2p.trf_time !== null
        ) {
          return new Date(this.editedItem.p2p.trf_time).toLocaleString()
        }
        else {
          return "";
        }
      },
      set(/*x*/) {
        // Empty
      }
    },
    computedCallbackTime: {
      get() {
        return new Date(this.editedItem.callback.callback_time)
          .toLocaleString();
      }
    },
    computedCallbackStatus() {
      const sts = this.editedItem.callback.callback_rsp_status;
      return `${this.$t(sts === 200 ? 'success' : 'failed')} (${sts})`;
    },
    rejectMessages() {
      return [
        this.$t('Timeout'),
        this.$t('Wrong username/password'),
        this.$t('Wrong OTP'),
        this.$t('Insufficient balance'),
        this.$t('Insufficient transfer limit')
      ];
    },
    timerPercent() {
      return this.timer / 600;
    }
  },
  data: function () {
    return {
      approveDialog: false,
      busy: false,
      receiptDialog: false,
      customerReceiptDialog: false,
      p2pReceiptDialog: false,
      authMethods: Object.keys(AuthMethod).map((key) => {
        return {name: key, code: AuthMethod[key]}
      }).filter((x) => x.code !== AuthMethod.NONE),
      snackbar: true,
      timer: 0,
    }
  },
  methods: {
    fetch() {
      //eslint-disable-next-line
      // console.log('taskform');
      this.$emit('fetch');
    },
    refresh() {
      // eslint-disable-next-line
      // console.log(this.editedItem);
      this.$emit('refresh', this.editedItem.transaction_number);
    },
    close() {
      this.$emit('close');
    },
    approveStep() {
      if (this.editedItem.transaction_type === this.TransactionType.TOP_UP || this.editedItem.transaction_type === this.TransactionType.COLLECT) {
        this.approveDialog = true;
      } else {
        this.approve();
      }
    },
    async approve() {
      let isValidationPassed = true;
      const _this = this;

      if (this.isCsAgentBot) {
        let approveUrl = `/app/api/transaction/${this.editedItem.transaction_number}/subcollectbcomplete`;
        axios.post(approveUrl, {
          remark: this.editedForm.remark || '',
        })
          .then(function () {
            _this.$store.dispatch('auth/removeAssignment', _this.editedItem.transaction_number).then(function () {
              // _this.fetch();
              _this.close();
            });
          })
          .catch(function (error) {
            // Show error.
            _this.$store.dispatch('snackbar/openSnackbar', {
              message: error.response.data.message,
              color: 'error',
            })
          })
          .finally(function () {
            _this.busy = false;
          })
      } else {
        if (this.editedItem.transaction_type === this.TransactionType.TOP_UP || this.editedItem.transaction_type === this.TransactionType.COLLECT) {
          if (!this.editedForm.act_bank_card_name || !this.editedForm.act_bank_card_name.trim()) {
            this.$refs.actBankCardNameRef.applyResult({
              errors: [this.$t('sender_bank_acc_is_required')],
              valid: false,
              failedRules: {}
            });
            isValidationPassed = false;
          }
          let result = await validate(this.editedForm.amount, {
            required: true,
            min_value: 1,
            ...(
              this.editedItem.paytype !== PayTypeEnum.P2P 
                ? { max_value: this.editedItem.amount }
                : {}
            ),
          }, {
            name: 'amount'
          });
          if (!result.valid) {
            this.$refs.amountRef.applyResult({
              errors: [result.errors[0]],
              valid: false,
              failedRules: {}
            });
            console.log(result); //eslint-disable-line
            isValidationPassed = false;
          }
          if (!isValidationPassed) {
            this.approveDialog = false;
            return;
          }
        }
        if (
          (this.editedItem.transaction_type === this.TransactionType.TOP_UP || this.editedItem.transaction_type === this.TransactionType.COLLECT) &&
          (!this.editedForm.act_bank_card_name || !this.editedForm.act_bank_card_name.trim())
        ) {
          this.$refs.actBankCardNameRef.applyResult({
            errors: [this.$t('sender_bank_acc_is_required')],
            valid: false,
            failedRules: {}
          });
          this.approveDialog = false;
          return;
        }
        if (
          this.editedItem.transaction_type === (this.TransactionType.WITHDRAW || this.TransactionType.PAY) &&
          !this.editedForm.receipt
        ) {
          this.$refs.receiptRef.applyResult({
            errors: [this.$t('receipt_is_required')],
            valid: false,
            failedRules: {}
          });
          this.approveDialog = false;
          return;
        }

        const formData = new FormData();

        formData.append('remark', this.editedForm.remark || '');
        if (this.editedItem.transaction_type === this.TransactionType.WITHDRAW || this.editedItem.transaction_type === this.TransactionType.PAY) {
          formData.append('receipt', this.editedForm.receipt);
        }
        if (this.editedItem.transaction_type === this.TransactionType.TOP_UP || this.editedItem.transaction_type === this.TransactionType.COLLECT) {
          formData.append('amount', this.editedForm.amount);
          formData.append('act_bank_card_name', this.editedForm.act_bank_card_name);
        }
        this.busy = true;
        let approveUrl = `/app/api/transaction/${this.editedItem.transaction_number}/complete`;

        axios({
          url: approveUrl,
          method: 'POST',
          data: formData,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
          },
        }).then(function () {
          // _this.transactionList.push(_this.editedItem)
          _this.$store.dispatch('auth/removeAssignment', _this.editedItem.transaction_number).then(function () {
            // _this.fetch();
            _this.close();
            _this.approveDialog = null;
          });
        }).catch(function (error) {
          // Show error.
          _this.$store.dispatch('snackbar/openSnackbar', {
            message: error.response.data.message,
            color: 'error',
          })
        })
          .finally(function () {
            _this.busy = false;
          })
      }
    },
    async reject() {
      if (!this.editedForm.remark) {
        this.$refs.remarkRef.applyResult({
          errors: [this.$t('remark_is_required_to_reject')],
          valid: false,
          failedRules: {}
        });
        this.approveDialog = false;
        return;
      }

      this.busy = true;
      const _this = this;

      let rejectUrl = `/app/api/transaction/${this.editedItem.transaction_number}/reject`;
      if (this.isCsAgentBot) {
        rejectUrl = `/app/api/transaction/${this.editedItem.transaction_number}/subcollectbreject`;
      }
      axios.post(rejectUrl, {
        remark: this.editedForm.remark || '',
      })
        .then(function () {
          _this.$store.dispatch('auth/removeAssignment', _this.editedItem.transaction_number).then(function () {
            // _this.fetch();
            _this.close();
          });
        })
        .catch(function (error) {
          // Show error.
          _this.$store.dispatch('snackbar/openSnackbar', {
            message: error.response.data.message,
            color: 'error',
          })
        })
        .finally(function () {
          _this.busy = false;
        })
    },
    copyToClipboard(value, type) {
      navigator.clipboard.writeText(value).then(() => {
        this.$store.dispatch('snackbar/openSnackbar', {
          message: this.$t('copy_success', [type]),
          color: 'success',
        })
      });
    },
    collect2fa() {
      this.busy = true;
      const _this = this;
      axios.post(`/app/api/transaction/${this.editedItem.transaction_number}/subcollectbauth`, {
        auth_method: _this.editedItem.collectb.auth_method,
        auth_hint: _this.editedItem.collectb.auth_hint 
            ? _this.editedItem.collectb.auth_hint 
            : undefined,
      })
        .then(function () {
          _this.$emit('2fa-submitted')
          _this.$store.dispatch('snackbar/openSnackbar', {
            message: _this.$t('2fa_request_sent'),
            color: 'success',
          })
          // _this.$store.dispatch('auth/removeAssignment', _this.editedItem.transaction_number).then(function () {
          //   _this.fetch();
          //   _this.close();
          // });
        })
        .catch(function (error) {
          // Show error.
          //eslint-disable-next-line
          console.log(error);
          _this.$store.dispatch('snackbar/openSnackbar', {
            message: error.response.data.message,
            color: 'error',
          })
        })
        .finally(function () {
          _this.busy = false;
        })
    },
    initTimer() {
      setTimeout(() => {
        this.timer = Date.now() - this.viewedAt;
        this.initTimer();
      }, 1000)
    },
    triggerManualCallback() {
      this.busy = true;
      const _this = this;
      const txn = this.editedItem.transaction_number;

      axios
        .post(`/app/api/transaction/${txn}/callback`)
        .then(function () {

          // If the client callback took too long and user close the dialog box
          // and open another transaction dialog box, this might cause some 
          // issue

          _this.refresh();
          _this.$store.dispatch('snackbar/openSnackbar', {
            message: _this.$t('manual_callback_success'),
            color: 'success',
          })
        })
        .catch(function (error) {
          // Show error.
          //eslint-disable-next-line
          console.log(error);
          _this.$store.dispatch('snackbar/openSnackbar', {
            message: error.response.data.message,
            color: 'error',
          })
        })
        .finally(function () {
          _this.busy = false;
        })
    },
  }
}
</script>

<style>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .5;
  position: absolute;
  width: 100%;
  cursor: pointer;
}
.text-input-green input {
  color: #43A047 !important;
  caret-color: #43A047 !important;
}
.text-input-red input {
  color: #E53935 !important;
  caret-color: #E53935 !important;
}
</style>
